import { Route, Routes } from "react-router-dom";

import PageBody from "shared/components/PageBody";
import LoadData from "./LoadData/LoadData";

const Create = () => {
  return (
    <PageBody>
      <header className="mb-4">
        <h1 className="text-2xl font-semibold leading-7 text-gray-900 my-10">
          Carga en lote
        </h1>
        <p className="text-xl">Realizar una carga en lote de biografías</p>
      </header>
      <Routes>
        <Route index element={<LoadData />} />
      </Routes>
    </PageBody>
  );
};

export default Create;
