import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import { createBatchLoad, createLink, uploadFile } from "lib/backend";
import Papa from "papaparse";

import { InputHTMLAttributes, useState } from "react";
import { useNavigate } from "react-router-dom";

interface CsvInputProps
  extends React.DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  onDataChange: (data: Papa.ParseResult<string>, file: File) => void;
}

const CsvInput = ({ onDataChange, ...props }: CsvInputProps) => {
  const [fileName, setFileName] = useState<string>();

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      try {
        const file: File = e.target.files[0];

        setFileName(e.target.files[0].name);

        Papa.parse(file, {
          skipEmptyLines: true,
          header: true,
          complete(results: Papa.ParseResult<any>, file: any) {
            onDataChange(results, file);
          },
        });
      } catch (error) {
        console.error(error); // TODO: show error
      }
    }
  };

  return (
    <label className="mb-4 block">
      <input
        {...props}
        className="hidden"
        type="file"
        accept=".csv"
        onChange={handleFileChange}
      />
      <span className="flex cursor-pointer justify-center items-center w-full h-40 border-2 rounded-md border-gray-300 bg-gray-100 text-center text-gray-600">
        {fileName ? (
          <span className="text-blue-700 italic">{fileName}</span>
        ) : (
          <span className="flex flex-row items-center gap-4">
            <DocumentPlusIcon width={"48px"} /> Cargar documento con biografías
            en lote
          </span>
        )}
      </span>
    </label>
  );
};

const MAX_USERS_PREVIEW = 10;

// TODO: generalize this
const COLUMNS = new Set([
  "APELLIDO, Nombre",
  "País",
  "Lugar nac.",
  "Fecha nac.",
  "Lugar fallec.",
  "Fecha fallec",
  "Breve descripción",
]);

const LoadData = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState<File>();
  const [csvData, setCsvData] = useState<any[]>();
  const [csvFields, setCsvFields] = useState<string[]>();

  return (
    <div>
      <CsvInput
        onDataChange={(data, file) => {
          setCsvData(data.data);
          setCsvFields(data.meta.fields);
          setFile(file);
        }}
      />
      <div className="mb-16 flex justify-end">
        <button
          onClick={async () => {
            if (file === undefined) {
              console.warn(
                "Es necesario seleccionar un archivo para la carga."
              );
              return;
            }

            const { url, loadId } = await createLink(file);
            await uploadFile(file, url);

            const urlObj = new URL(url);
            const key = urlObj.pathname.split("/").slice(-2).join("/");

            await createBatchLoad({ key, loadId });

            navigate(`/cargas/${loadId}`);
          }}
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Comenzar
        </button>
      </div>
      {csvData && (
        <>
          <div className="mb-2">
            <h2 className="font-bold">
              Visualización de datos ({csvData.length} filas)
            </h2>
            <p className="text-sm">
              * Las columnas en gris no serán utilizadas en la carga.
            </p>
            <p className="text-sm">
              * Se muestran hasta {MAX_USERS_PREVIEW} filas.
            </p>
          </div>
          <table className="text-center overflow-x-auto w-full">
            <thead>
              <tr className="border-b-4 border-b-gray-800">
                {csvFields?.map((field) => (
                  <th
                    className={`${
                      COLUMNS.has(field) ? "" : "text-gray-600 bg-gray-200"
                    } px-4 py-1 border-2`}
                  >
                    {field}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {csvData
                ?.slice(0, Math.min(csvData.length, MAX_USERS_PREVIEW))
                .map((row) => (
                  <tr className="border-b-2 border-b-gray-200">
                    {csvFields?.map((field) => (
                      <td
                        className={`${
                          COLUMNS.has(field) ? "" : "text-gray-600 bg-gray-200"
                        } px-4 py-1 border-x-2`}
                      >
                        {row[field]}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default LoadData;
