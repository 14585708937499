import { useMutation } from "@tanstack/react-query";
import PageBody from "../../../shared/components/PageBody";
import { createUser } from "lib/backend";
import { useState } from "react";
import { H1 } from "shared/components/headers";
import { useNavigate } from "react-router-dom";

const Create = () => {
  const navigate = useNavigate();

  const [fullName, setFullName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [temporaryPassword, setTemporaryPassword] = useState<string>();
  const [isActive, setIsActive] = useState(false);
  const [role, setRole] = useState<string>();

  const mutation = useMutation(
    ["userCreate"],
    () => {
      if (
        fullName === undefined ||
        email === undefined ||
        temporaryPassword === undefined ||
        isActive === undefined ||
        role === undefined
      )
        return Promise.resolve(null);

      return createUser({
        FullName: fullName,
        Email: email,
        TemporaryPassword: temporaryPassword,
        IsActive: isActive,
        Role: role,
      });
    },
    {
      onSuccess: (data) => {
        // TODO: avisar que se actualizo okas
        const user = data?.data;
        const pk = user.PK;
        const id = pk.replace(/^USER#/, "");

        navigate(`/usuarios/${id}`);
      },
      onError: (error) => {
        alert(error);
      },
    }
  );

  return (
    <PageBody>
      <header className="mb-4">
        <H1>Crear usuario</H1>
        <p className="text-2xl">Datos del usuario</p>
      </header>
      <div className="grid grid-cols-6 items-end gap-x-6 gap-y-8">
        <div className="col-span-3">
          <label
            htmlFor="full-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Nombre
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="full-name"
              id="full-name"
              autoComplete="given-name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="col-span-3">
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Email
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="email"
              id="email"
              autoComplete="given-name"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="col-span-2">
          <label
            htmlFor="is-active"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Activo
          </label>
          <div className="mt-2">
            <input
              type="checkbox"
              name="is-active"
              id="is-active"
              checked={isActive}
              onChange={() => setIsActive(!isActive)}
              autoComplete="given-name"
              className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="col-span-2">
          <label
            htmlFor="role"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Rol
          </label>
          <div className="mt-2">
            <select
              name="role"
              id="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
              <option value="EDITOR">Editor</option>
              <option value="ADMIN">Administrador</option>
            </select>
          </div>
        </div>
        <div className="col-span-2">
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Contraseña temporal
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="password"
              id="password"
              autoComplete="password"
              value={temporaryPassword}
              onChange={(e) => setTemporaryPassword(e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>
      <div className="mt-4 flex justify-end gap-3">
        <button
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => {
            mutation.mutate();
          }}
        >
          Crear
        </button>
      </div>
    </PageBody>
  );
};

export default Create;
