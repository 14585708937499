import { UserPlusIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { User, listUsers } from "lib/backend";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import PageBody from "shared/components/PageBody";
import SkeletonList from "shared/components/SkeletonList";

const List = () => {
  const query = useQuery({
    queryKey: ["listUsers"],
    queryFn: () => listUsers(),
    enabled: false,
  });

  useEffect(() => {
    query.refetch();
  }, [query]);

  return (
    <PageBody>
      <header className="mb-4 text-2xl font-semibold leading-7 text-gray-900 my-10">
        <h1 className="text-2xl font-semibold leading-7 text-gray-900 my-10">
          Usuarios
        </h1>
        <div className="flex flex-row text-base">
          <Link
            className="flex flex-row items-center gap-2 rounded-md bg-green-600 px-3 py-2 text-medium font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            to={"nuevo"}
          >
            <UserPlusIcon className="h-8" /> Usuario nuevo
          </Link>
        </div>
      </header>
      <table className="table-auto min-w-full">
        <thead className="border-b-2 border-b-slate-200">
          <tr>
            <th className="p-4">ID</th>
            <th>Email</th>
            <th>Nombre</th>
            <th>Activo</th>
            <th>Rol</th>
            <th>Creado en</th>
            <th>Acciones</th>
          </tr>
        </thead>
        {query.isLoading ? (
          <tbody>
            <tr>
              <td colSpan={7}>
                <SkeletonList />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {query.data?.map((u: User) => (
              <tr className="text-center hover:bg-slate-100 border-b-2 border-slate-50">
                <td className="p-4">{u.PK}</td>
                <td>{u.Email}</td>
                <td>{u.FullName}</td>
                <td>{u.IsActive ? "Sí" : "No"}</td>
                <td>{u.Role}</td>
                <td title={u.CreatedAt}>
                  {new Date(u.CreatedAt).toLocaleDateString() +
                    " " +
                    new Date(u.CreatedAt).toLocaleTimeString()}
                </td>
                <td>
                  <Link
                    to={`/usuarios/${u.PK}`}
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Editar
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </PageBody>
  );
};

export default List;
