import { useEffect, useState } from "react";
import { AuthorDetected, paintAuthorsInBiography } from "./utils";
import { capitalize } from "shared/utils/strings";

interface Props {
  firstName?: string;
  lastName?: string;
  pseudonyms?: string;
  warNames?: string;
  generalData?: string;
  briefDescription?: string;
  body?: string;
  authorsDetected?: AuthorDetected[];
  work?: string;
  authors?: string[];
  highlightAuthor?: string;
}

const capitalizeFirstLetter = (word: string) =>
  word.charAt(0).toUpperCase() + word.slice(1);

const BiographyPreview = ({
  firstName,
  lastName,
  pseudonyms,
  warNames,
  generalData,
  briefDescription,
  body,
  authors,
  work,
  authorsDetected,
  highlightAuthor,
}: Props) => {
  const [biographyProcessed, setBiographyProcessed] = useState<string>();

  useEffect(() => {
    const processedBiography = paintAuthorsInBiography(
      body,
      authorsDetected,
      highlightAuthor
    );

    setBiographyProcessed(processedBiography);
  }, [authorsDetected, highlightAuthor, body]);

  return (
    <div>
      <h2 className="text-xl font-semibold leading-7 text-gray-900">
        Previsualización
      </h2>
      <p className="mt-1 pb-6 border-b-2 text-sm leading-6 text-gray-600">
        Vista previa de cómo se verá en WordPress.
      </p>
      <h3 className="font-bold text-xl leading-3 pt-6 text-center">
        {(lastName || firstName) &&
          `${lastName?.toUpperCase()}, ${capitalize(firstName || "")}`}
      </h3>
      <p className="text-sm mt-4">
        {(lastName || firstName) && (
          <i>
            {lastName?.toUpperCase()}, {capitalize(firstName || "")}{" "}
          </i>
        )}
        {pseudonyms && <span>(seudónimos: {pseudonyms}) </span>}
        {warNames && <span>(nombres de guerra: {warNames}) </span>}
        {generalData && `(${generalData})`}
      </p>
      <p className="text-sm font-bold mt-4">{briefDescription}</p>
      <div className="relative">
        <div
          className="columns-2 mt-4 preview-biography-block text-justify "
          dangerouslySetInnerHTML={{ __html: biographyProcessed || "" }}
        />
      </div>
      {work && (
        <div>
          <h2 className="font-bold text-lg leading-3 pt-6">Obra</h2>
          <div
            className="mt-4 preview-biography-block text-justify text-sm"
            dangerouslySetInnerHTML={{ __html: work || "" }}
          />
        </div>
      )}
      {(lastName || firstName) && (
        <p className="bg-gray-200 p-4 mt-4 border-l-8 border-neutral-500">
          Cómo citar esta entrada: <span>{authors && authors.join(" y ")}</span>{" "}
          (2023), “
          <i>
            {lastName},{" "}
            {firstName?.split(" ").map(capitalizeFirstLetter).join(" ")}
          </i>
          ”, en Diccionario biográfico de las izquierdas latinoamericanas.
          Disponible en{" "}
          <a className="underline" href="http://diccionario.cedinci.org">
            http://diccionario.cedinci.org
          </a>
        </p>
      )}
    </div>
  );
};

export default BiographyPreview;
