import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { WpCategory } from "lib/models/WpCategory";
import { RootState } from 'store';

interface CategoriesState {
  categories: WpCategory[];
  categoriesById: { [id: number]: WpCategory };
}

const initialState: CategoriesState = {
  categories: [],
  categoriesById: {}
}

export const selectCategories =
  (state: RootState): CategoriesState["categories"] => state.categories.categories;

export const selectCategoriesById =
  (state: RootState): CategoriesState["categoriesById"]  => state.categories.categoriesById;

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    set: (state: CategoriesState, action: PayloadAction<WpCategory[]>) => {
      const { payload } = action;

      state.categories = [...payload].sort((a, b) => a.name.localeCompare(b.name));
      state.categoriesById = payload.reduce((prev, curr) => {
        prev[curr.id] = curr;

        return prev;
      }, {} as CategoriesState["categoriesById"]);
    }
  }
})

export const { set } = categoriesSlice.actions;

export default categoriesSlice.reducer;