import { Link } from "react-router-dom";
import PageBody from "shared/components/PageBody";
import Spinner from "shared/components/Spinner";

interface Props {
  nextPath?: string;
  nextLabel?: string;
  onNext?: () => void;
  nextDisabled?: boolean;
  previousPath?: string;
  previousLabel?: string;
  onPrevious?: () => void;
  children: any;
}

const FlowStep = (props: Props) => {
  const { nextPath, nextLabel, onNext, nextDisabled = false } = props;
  const { previousPath, previousLabel, onPrevious } = props;

  return (
    <PageBody>
      <div>{props.children}</div>
      <div className="w-full flex flex-row-reverse fixed bottom-0 right-0 px-8 py-2 pointer-events-none">
        <div className="flex items-center justify-end gap-x-6 my-4 px-4 py-2">
          {previousPath !== undefined && (
            <Link
              to={previousPath}
              onClickCapture={() => onPrevious !== undefined && onPrevious()}
              className="text-sm font-semibold leading-6 text-gray-900 pointer-events-auto"
            >
              {previousLabel || "Volver"}
            </Link>
          )}
          {nextPath !== undefined && (
            <Link
              to={nextPath}
              onClickCapture={() => onNext !== undefined && onNext()}
              className={`${
                nextDisabled ? "pointer-events-none" : "pointer-events-auto"
              } rounded-md ${
                nextDisabled ? "bg-indigo-200" : "bg-indigo-600"
              } px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
            >
              {nextLabel || "Siguiente"}
            </Link>
          )}
        </div>
      </div>
    </PageBody>
  );
};

export default FlowStep;
