import { useContext } from "react";
import { CreateContext } from "../Create";
import { WpCategory } from "lib/models/WpCategory";

interface Props {
  indexedCategories: { [key: string]: WpCategory };
}

const SelectedCategory = ({
  category,
  onRemove,
}: {
  category: WpCategory;
  onRemove: (categoryId: number) => void;
}) => {
  return (
    <span className="p-3 bg-gray-100 rounded-lg text-sm grow-0 whitespace-nowrap flex gap-2">
      <span>{category.name}</span>
      <button className="font-bold" onClick={() => onRemove(category.id)}>
        X
      </button>
    </span>
  );
};

const SelectedViewer = ({ indexedCategories }: Props) => {
  const { selectedCategories, setSelectedCategories } =
    useContext(CreateContext);

  return (
    <div className="inline-flex flex-wrap gap-2">
      {Object.entries(selectedCategories || {})
        .filter(([categoryId, isSelected]) => isSelected)
        .sort((a, b) =>
          indexedCategories[a[0]].name.localeCompare(
            indexedCategories[b[0]].name
          )
        )
        .map(([categoryId, _]) => (
          <SelectedCategory
            key={categoryId}
            category={indexedCategories[categoryId]}
            onRemove={() => {
              setSelectedCategories({
                ...selectedCategories,
                [categoryId]: false,
              });
            }}
          />
        ))}
    </div>
  );
};

export default SelectedViewer;
