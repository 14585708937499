import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

const PageBody = ({ children }: Props) => {
  return (
    <div className="relative min-h-full min-w-full px-5 py-10">{children}</div>
  );
};

export default PageBody;
