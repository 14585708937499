import { createContext, ReactNode, useState } from "react";

// Define the type for your context data
export type AuthContextData = {
  tokens: {
    id: string | null;
    access: string | null;
    refresh: string | null;
  };
  setTokens: (id: string | null, access: string | null, refresh: string | null) => void;
};

// Create a context with the specified type
export const AuthContext = createContext<AuthContextData | undefined>(
  undefined
);

// Create a context provider component
type AuthContextProviderProps = {
  children: ReactNode;
};

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [tokens, _setTokens] = useState<AuthContextData["tokens"]>({
    id: localStorage.getItem("id_token"),
    access: localStorage.getItem("access_token"),
    refresh: localStorage.getItem("refresh_token"),
  });

  const setTokens = (
    idToken: string | null, 
    accessToken: string | null, 
    refreshToken: string | null
  ) => {
    idToken
      ? localStorage.setItem("id_token", idToken)
      : localStorage.removeItem("id_token");

    accessToken
      ? localStorage.setItem("access_token", accessToken)
      : localStorage.removeItem("access_token");

    refreshToken
      ? localStorage.setItem("refresh_token", refreshToken)
      : localStorage.removeItem("refresh_token");

    _setTokens({ id: idToken, access: accessToken, refresh: refreshToken });
  };

  const contextData: AuthContextData = {
    tokens,
    setTokens,
  };

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
