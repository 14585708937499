import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const H2 = ({ children }: Props) => {
  return (
    <h2 className="text-xl font-semibold leading-7 text-gray-800 mb-2">
      {children}
    </h2>
  );
};

export default H2;
