import { openDB } from 'idb';

const dbName = 'backoffice';
export const stores = {
  categories: {
    name: 'categories',
    schema: { keyPath: 'id', autoIncrement: true },
  }
};

export const openDatabase = async () => {
  const db = await openDB(dbName, 1, {
    upgrade(db) {
      Object.entries(stores).forEach(([_key, value]) => {
        if (db.objectStoreNames.contains(value.name)) return;

        db.createObjectStore(value.name, value.schema);
      });
    },
  });

  return db;
};

export const get = async (storeName: string, id: number) => {
  const db = await openDatabase();
  const tx = db.transaction(storeName, 'readonly');
  const store = tx.objectStore(storeName);

  return await store.get(id);
};

export const save = async (storeName: string, category: any) => {
  const db = await openDatabase();
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);

  await store.put(category);

  return category;
};

export const getAll = async (storeName: string) => {
  const db = await openDatabase();
  const tx = db.transaction(storeName, 'readonly');
  const store = tx.objectStore(storeName);

  return store.getAll();
};
