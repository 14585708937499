import { Route, Routes } from "react-router-dom";
import Cognito from "./Cognito/Cognito";

const Oauth = () => {
  return (
    <Routes>
      <Route path="cognito" element={<Cognito />} />
    </Routes>
  );
};

export default Oauth;
