import axios from "axios";
import { WpCategory } from "./models/WpCategory";

export const listCategories = async (page: number): Promise<WpCategory[]> => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_DICCIONARIO_WP_URL}/wp-json/wp/v2/categories`,
    {
      params: { page, per_page: 50 },
    }
  );
  
  return data;
};