import { useQuery } from "@tanstack/react-query";
import {
  loginUrl,
  minutesUntilTokenExpiration,
  verifyToken,
  refreshTokens as refreshCognitoTokens,
  logoutUrl
} from "lib/cognito";
import { ReactElement, useContext, useEffect, useState } from "react";
import { AuthContext, type AuthContextData } from "./AuthContext";

const logout = () => {
  window.location.href = logoutUrl().href;

  return null;
}

const refreshTokens = (
  refreshToken: string,
  setTokens: AuthContextData["setTokens"],
  setTokenVerified: (isVerified: boolean) => void
): Promise<void | null> => {
  return refreshCognitoTokens(refreshToken)
    .then(({ data }) => {
      setTokens(data.id_token, data.access_token, refreshToken);
      setTokenVerified(true);
    })
    .catch(() => logout());
}

const REFRESH_PERIOD_BEFORE_EXPIRATION = 10;

const NeedsAuth = ({ children }: { children: ReactElement }) => {
  const {
    tokens: { id: idToken, access: accessToken, refresh: refreshToken },
    setTokens
  } = useContext(AuthContext)!;

  const refreshTokenQuery =  useQuery({
    queryKey: ["refreshToken", refreshToken],
    queryFn: async () => {
      if (refreshToken === null) return;

      refreshTokens(refreshToken, setTokens, setTokenVerified);
    },
    enabled: false
  });

  const [tokenVerified, setTokenVerified] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    if (refreshToken === null) {
      logout();
      return;
    }

    if (idToken === null || accessToken === null) {
      refreshTokenQuery.refetch();
      return;
    }

    Promise.all(
      [verifyToken(idToken, "id"), verifyToken(accessToken, "access")]
    ).then((verifiedTokens) => {
      const allVerified = verifiedTokens.reduce((acc, curr) => acc && curr, true);

      if (!allVerified) {
        refreshTokenQuery.refetch();
      } else {
        setTokenVerified(true);
      }
    })
  }, [refreshTokenQuery, idToken, accessToken, refreshToken, setTokens, setTokenVerified]);

  useEffect(() => {
    if (refreshToken === null) return;

    const interval = setInterval(() => {
      if (accessToken
          && minutesUntilTokenExpiration(accessToken) > REFRESH_PERIOD_BEFORE_EXPIRATION)
        return;
    
      refreshTokenQuery.refetch();
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, [refreshTokenQuery, refreshToken, accessToken, setTokens]);

  if (tokenVerified === undefined) {
    return null;
  } else if (tokenVerified === false) {
    window.location.href = loginUrl();

    return null;
  } else {
    return children;
  }
};

export default NeedsAuth;