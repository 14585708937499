import { useContext, useState } from "react";
import {
  AuthorFullName,
  AuthorLink,
  ExistingAuthorLink,
  NewAuthorLink,
} from "./utils";
import AuthorSearch from "./AuthorSearch";
import AuthorCreate from "./AuthorCreate";
import FlowStep from "../shared/FlowStep";
import { CreateContext } from "../Create";
import { detectAuthors } from "../shared/utils";
import BiographyPreview from "../shared/BiographyPreview";
import { H1 } from "shared/components/headers";

const NewAuthorSelected = ({
  subjectName,
  firstName,
  lastName,
  onRemove,
}: NewAuthorLink & { onRemove: (subjectName: string) => void }) => (
  <div>
    <p>
      Se creará una nueva entrada para{" "}
      <strong>
        {firstName} {lastName}
      </strong>{" "}
      ({subjectName})
    </p>
    <div className="flex flex-row justify-end px-4 pt-4">
      <button
        onClick={() => onRemove(subjectName)}
        className="sm:col-span-1 max-h-10 rounded-md border-slate-600 border-2 px-3 py-2 text-sm font-semibold text-slate-600 shadow-sm hover:bg-slate-400 hover:text-white  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-slate-400 focus-visible:text-white"
      >
        Cancelar
      </button>
    </div>
  </div>
);

const ExistingAuthorSelected = ({
  subjectName,
  postTitle,
  postUrl,
  onRemove,
}: ExistingAuthorLink & { onRemove: (subjectName: string) => void }) => (
  <div>
    <p>
      Se vinculará a <strong>{subjectName}</strong> con el artículo{" "}
      <strong>{postTitle}</strong> (
      <a href={postUrl} className="underline">
        {postUrl}
      </a>
      )
    </p>
    <div className="flex flex-row justify-end px-4 pt-4">
      <button
        onClick={() => onRemove(subjectName)}
        className="sm:col-span-1 max-h-10 rounded-md border-slate-600 border-2 px-3 py-2 text-sm font-semibold text-slate-600 shadow-sm hover:bg-slate-400 hover:text-white  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-slate-400 focus-visible:text-white"
      >
        Cancelar
      </button>
    </div>
  </div>
);

interface AuthorProps {
  author: AuthorFullName;
  onMouseOver: () => void;
  onMouseLeave: () => void;
}

const Author = (props: AuthorProps) => {
  const { author } = props;
  const [authorLink, setAuthorLink] = useState<AuthorLink | null>(null);
  const { linkedAuthors, setLinkedAuthors, newAuthors, setNewAuthors } =
    useContext(CreateContext);

  return (
    <div
      className={`border-t-2 border-t-slate-400 py-4 px-2 ${
        authorLink ? "bg-slate-100" : ""
      }`}
      onMouseOver={() => props.onMouseOver()}
      onMouseLeave={() => props.onMouseLeave()}
    >
      <h2
        className={`text-2xl mb-4 font-semibold leading-7 text-gray-900 ${
          authorLink ? "text-slate-500" : ""
        }`}
      >
        {author}
      </h2>
      {authorLink === null ? (
        <div className="flex grow flex-row px-4">
          <div className="flex grow flex-col">
            <AuthorSearch
              fullName={author}
              onAuthorSelected={(option) => {
                const authorLink: ExistingAuthorLink = {
                  subjectName: props.author ?? "",
                  isNew: false,
                  postUrl: option["link"],
                  postTitle: option["title"]["rendered"],
                };

                setAuthorLink(authorLink);
                setLinkedAuthors({
                  ...linkedAuthors,
                  [author ?? ""]: authorLink,
                });
              }}
            />
            <div className="flex flex-row items-center py-2">
              <span className="grow border-b" />
              <span className="shrink-0 px-4 text-slate-300">o</span>
              <span className="grow border-b" />
            </div>
            <AuthorCreate
              fullName={author}
              onAuthorConfirmed={(firstName, lastName) => {
                const authorLink: NewAuthorLink = {
                  subjectName: props.author ?? "",
                  isNew: true,
                  firstName,
                  lastName,
                };

                setAuthorLink(authorLink);
                setNewAuthors({
                  ...newAuthors,
                  [author ?? ""]: authorLink,
                });
              }}
            />
          </div>
        </div>
      ) : authorLink.isNew ? (
        <NewAuthorSelected
          {...authorLink}
          onRemove={() => {
            const { [authorLink.subjectName]: _, ...rest } = newAuthors;

            setNewAuthors(rest);
            setAuthorLink(null);
          }}
        />
      ) : (
        <ExistingAuthorSelected
          {...authorLink}
          onRemove={() => {
            const { [authorLink.subjectName]: _, ...rest } = linkedAuthors;

            setLinkedAuthors(rest);
            setAuthorLink(null);
          }}
        />
      )}
    </div>
  );
};

const LinkAuthors = () => {
  const [highlightAuthor, setHighlightAuthor] = useState<string>("");
  const { biographyData } = useContext(CreateContext);
  const { body } = biographyData;

  const detectedAuthors = detectAuthors(body ?? "");

  return (
    <FlowStep
      nextLabel="Siguiente"
      previousLabel="Volver"
      nextPath={"../categorias"}
      previousPath={"../"}
    >
      <div className="w-full flex justify-left">
        <div className="flex flex-row items-start w-full">
          <div className="flex flex-1 flex-col justify-between w-full">
            <H1>
              Vincular autores
            </H1>
            {detectedAuthors.length > 0 ? (
              <div className="flex-1 pl-2 pr-4">
                {detectedAuthors
                  .slice()
                  .reverse()
                  .map((author) => (
                    <Author
                      author={author.fullName}
                      key={author.fullName}
                      onMouseOver={() =>
                        setHighlightAuthor(author.fullName ?? "")
                      }
                      onMouseLeave={() => setHighlightAuthor("")}
                    />
                  ))}
              </div>
            ) : (
              <p>No hay autores para vincular</p>
            )}
          </div>
          <div className="flex-1 p-4 border-l-4 pl">
            <BiographyPreview
              firstName={biographyData.firstName}
              lastName={biographyData.lastName}
              generalData={biographyData.generalData}
              briefDescription={biographyData.briefDescription}
              body={biographyData.body}
              authorsDetected={detectedAuthors}
              authors={biographyData.authors}
              highlightAuthor={highlightAuthor}
            />
          </div>
        </div>
      </div>
    </FlowStep>
  );
};

export default LinkAuthors;
