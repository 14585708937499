// TODO: test
export const pascalToCamelObj = (obj: any) => {
  const newObj: any = {};

  for (const key in obj) {
    const newKey = key[0].toLowerCase() + key.slice(1);
    newObj[newKey] = obj[key];
  }

  return newObj;
};

// Extracted from https://stackoverflow.com/a/36566052
const editDistance = (s1: string, s2: string): number => {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  var costs = [];
  for (var i = 0; i <= s1.length; i++) {
    var lastValue = i;

    for (var j = 0; j <= s2.length; j++) {
      if (i === 0) costs[j] = j;
      else {
        if (j > 0) {
          var newValue = costs[j - 1];
          if (s1.charAt(i - 1) !== s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
};

export const similarity = (s1: string, s2: string): number => {
  var longer = s1;
  var shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  var longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  return (
    (longerLength - editDistance(longer, shorter)) /
    parseFloat(longerLength.toString())
  );
};

const removeClassAttributes = (element: Element | null) => {
  if (element === null) return;
  if (element.hasAttribute("class")) element.removeAttribute("class");

  const children = element.children;
  for (let i = 0; i < children.length; i++)
    removeClassAttributes(children.item(i));
};

export const sanitizeBiographyBody = (body: string) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = body;

  removeClassAttributes(tempElement);

  return tempElement.innerHTML;
};
