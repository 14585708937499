import { Route, Routes } from "react-router-dom";

import ListLoads from "./List/List";
import CreateIndividualLoad from "./Individual/Create/Create";
import BatchLoads from "./Batch/Batch";
import Show from "./Show/Show";

const Loads = () => {
  return (
    <Routes>
      <Route index element={<ListLoads />} />
      <Route path="individual/nueva/*" element={<CreateIndividualLoad />} />
      <Route path="lote/*" element={<BatchLoads />} />
      <Route path=":id" element={<Show />} />
    </Routes>
  );
};

export default Loads;
