import { Route, Routes } from "react-router-dom";
import LoadData from "./LoadData/LoadData";
import LinkAuthors from "./LinkAuthors/LinkAuthors";
import ConfirmData from "./ConfirmData/ConfirmData";
import { AuthorDetected } from "./shared/utils";
import { createContext, useState } from "react";
import { BiographyData } from "./types";
import Success from "./Success/Success";
import { ExistingAuthorLink, NewAuthorLink } from "./LinkAuthors/utils";
import SelectCategories from "./SelectCategories/SelectCategories";

type SelectedCategories = { [id: number]: boolean };

interface CreateContextProp {
  authorsDetected: AuthorDetected[];
  setAuthorsDetected: (authorsDetected: AuthorDetected[]) => void;
  biographyData: BiographyData;
  setBiographyData: (biographyData: BiographyData) => void;
  newAuthors: { [subjectName: string]: NewAuthorLink };
  setNewAuthors: (data: { [subjectName: string]: NewAuthorLink }) => void;
  linkedAuthors: { [subjectName: string]: ExistingAuthorLink };
  setLinkedAuthors: (data: {
    [subjectName: string]: ExistingAuthorLink;
  }) => void;
  selectedCategories?: SelectedCategories;
  setSelectedCategories: (categories: SelectedCategories) => void;
}

export const CreateContext = createContext<CreateContextProp>({
  authorsDetected: [],
  setAuthorsDetected: () => {},
  biographyData: {},
  setBiographyData: () => {},
  linkedAuthors: {},
  setLinkedAuthors: () => {},
  newAuthors: {},
  setNewAuthors: () => {},
  selectedCategories: [],
  setSelectedCategories: () => {},
});

const Create = () => {
  const [authorsDetected, setAuthorsDetected] = useState<AuthorDetected[]>([]);
  const [biographyData, setBiographyData] = useState<BiographyData>({});
  const [newAuthors, setNewAuthors] = useState<{
    [subjectName: string]: NewAuthorLink;
  }>({});
  const [linkedAuthors, setLinkedAuthors] = useState<{
    [subjectName: string]: ExistingAuthorLink;
  }>({});
  const [selectedCategories, setSelectedCategories] =
    useState<SelectedCategories>();

  return (
    <CreateContext.Provider
      value={{
        authorsDetected,
        setAuthorsDetected,
        biographyData,
        setBiographyData,
        newAuthors,
        setNewAuthors,
        linkedAuthors,
        setLinkedAuthors,
        selectedCategories,
        setSelectedCategories,
      }}
    >
      <Routes>
        <Route index element={<LoadData />} />
        <Route path="vincular-autores" element={<LinkAuthors />} />
        <Route path="categorias" element={<SelectCategories />} />
        <Route path="confirmar" element={<ConfirmData />} />
        <Route path="sumario" element={<Success />} />
      </Routes>
    </CreateContext.Provider>
  );
};

export default Create;
