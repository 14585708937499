import LoadStatusBadge from "features/Loads/List/LoadStatusBadge";
import { BatchLoad } from "features/Loads/Show/Show";
import { useState } from "react";
import PageBody from "shared/components/PageBody";
import { H1, H3 } from "shared/components/headers";

interface Row {
  SubjectName: string;
  Description: string;
  WpPostId: number;
  CreatedAt: string;
}

interface ProcessedRowsProps {
  rows?: Row[];
}

const ProcessedRows = ({ rows }: ProcessedRowsProps) => {
  const [nameFilter, setNameFilter] = useState<string>();

  return (
    <div className="py-10">
      <div className="flex gap-2 flex-row justify-between pb-4">
        <H3>Filas procesadas</H3>
        <div className="flex flex-row gap-2 items-center">
          <span>Nombre:</span>
          <input
            type="text"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="overflow-y-auto max-h-72">
        <table className="table-auto min-w-full">
          <thead className="border-b-2 border-b-slate-200 sticky top-0 bg-white">
            <th className="p-4">Nombre</th>
            <th>Propiedades</th>
            <th>WP Link</th>
          </thead>
          <tbody>
            {rows
              ?.filter((row: Row) =>
                nameFilter
                  ? row.SubjectName.toLowerCase().includes(
                      nameFilter.toLowerCase()
                    )
                  : true
              )
              .map((row: Row) => (
                <tr className="text-center hover:bg-slate-100 border-b-2 border-slate-50">
                  <td className="p-4">{row.SubjectName}</td>
                  <td className="font-mono">{row.Description}</td>
                  <td>{row.WpPostId}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

interface FailedRowsProps {
  rows?: Row[];
}

const FailedRows = ({ rows }: FailedRowsProps) => {
  const [nameFilter, setNameFilter] = useState<string>();

  return (
    <div className="py-10">
      <div className="flex gap-2 flex-row justify-between pb-4">
        <H3>Filas fallidas</H3>
        <div className="flex flex-row gap-2 items-center">
          <span>Nombre:</span>
          <input
            type="text"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="overflow-y-auto max-h-72">
        {rows && rows.length > 0 ? (
          <table className="table-auto min-w-full">
            <thead className="sticky top-0 border-b-2 border-b-slate-200">
              <th className="p-4">Nombre</th>
              <th>Propiedades</th>
              <th>Errores</th>
            </thead>
            <tbody>
              {rows
                ?.filter((row: Row) =>
                  nameFilter
                    ? row.SubjectName.toLowerCase().includes(
                        nameFilter.toLowerCase()
                      )
                    : true
                )
                .map((row: Row) => (
                  <tr className="text-center hover:bg-slate-100 border-b-2 border-slate-50">
                    <td className="p-4">{row.SubjectName}</td>
                    <td className="font-mono">
                      {JSON.stringify(row.Description)}
                    </td>
                    <td className="font-mono text-left pl-6 py-2">
                      {row.Description}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <p>No hay errores.</p>
        )}
      </div>
    </div>
  );
};

interface Props {
  load: BatchLoad;
  events: any[];
}

const Show = ({ load, events }: Props) => {
  const successEvents = events.filter(
    (event) => event.EventName === "batch_post_created"
  );
  const failedEvents = events.filter(
    (event) => event.EventName === "batch_post_failed"
  );

  return (
    <PageBody>
      <header className="pb-5">
        <H1>{load.Filename}</H1>
        <p>
          <LoadStatusBadge status={load.Status} /> | {load.PK} | Descargar CSV
        </p>
      </header>
      <div>
        <ProcessedRows rows={successEvents} />
        <FailedRows rows={failedEvents} />
      </div>
    </PageBody>
  );
};

export default Show;
