import { useState } from "react";

interface RadioSelectorInput {
  options: { value: string; label: string }[];
  onOptionSelected: (option: string) => void;
}

const IGNORED_OPTION_CLASSES = "leading-6 text-gray-500 hover:py-2";
const SELECTED_OPTION_CLASSES =
  "rounded-md bg-blue-600 py-2 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600";

const Option = ({
  option,
  isSelected,
}: {
  option: RadioSelectorInput["options"][0];
  isSelected: boolean;
}) => {
  return (
    <label
      className={`text-sm px-3 font-semibold transition-all ${
        isSelected ? SELECTED_OPTION_CLASSES : IGNORED_OPTION_CLASSES
      }`}
      htmlFor={option.value}
    >
      {option.label}
    </label>
  );
};

const RadioSelector = ({ options, onOptionSelected }: RadioSelectorInput) => {
  const [selected, setSelected] = useState<string>(options[0].value);

  return (
    <div className="inline-flex flex-row p-3 gap-4 bg-gray-50 shadow-inner rounded-md">
      {options.map((option) => (
        <div key={option.value}>
          <input
            type="radio"
            name="status"
            value={option.value}
            id={option.value}
            onChange={(e) => {
              onOptionSelected(e.target.value);
              setSelected(e.target.value);
            }}
            className="hidden"
          />
          <Option option={option} isSelected={selected === option.value} />
        </div>
      ))}
    </div>
  );
};

export default RadioSelector;
