import { useContext, useMemo, useState } from "react";
import FlowStep from "../shared/FlowStep";
import SelectedViewer from "./SelectedViewer";
import { H1, H2 } from "shared/components/headers";
import CreateStepper from "../shared/Stepper";
import { CreateContext } from "../Create";
import { useAppSelector } from "hooks";
import { selectCategories, selectCategoriesById } from "reducers/categoriesSlice";
import { WpCategory } from "lib/models/WpCategory";

const CategoryGroup = ({
  children,
  level,
  searchTerm,
}: {
  children: Node[];
  level: number;
  searchTerm: string | null;
}) => {
  const { selectedCategories, setSelectedCategories } =
    useContext(CreateContext)!;

  return (
    <div>
      {children.map((node) => {
        if (node === null || !node.value) return null;

        if (searchTerm && !treeSearch(searchTerm, node))
          return null

        const value = node.value;

        return (
          <div
            key={value.id}
            className={`pl-6 mt-1 ${level === 0 ? "font-bold" : "font-normal"}`}
          >
            <label className="flex flex-row items-center hover:underline hover:cursor-pointer">
              <input
                type="checkbox"
                checked={
                  selectedCategories !== undefined
                    ? selectedCategories[value.id] ?? false
                    : false
                }
                className="mr-2"
                onChange={(e) => {
                  setSelectedCategories({
                    ...selectedCategories,
                    [value.id]: e.target.checked,
                  });
                }}
              />
              <span className="grow">{value.name}</span>
            </label>
            {node.children && (
              <CategoryGroup children={node.children} level={level + 1} searchTerm={searchTerm} />
            )}
          </div>
        );
      })}
    </div>
  );
};

const RootCategory = ({ node, searchTerm }: { node: Node, searchTerm: string }) => {
  const [_isOpen, setIsOpen] = useState(false);
  const foundTerm = treeSearch(searchTerm, node);
  const isDisabled = searchTerm && !foundTerm;
  const isOpen = (searchTerm && foundTerm) || _isOpen;

  if (node.children.length === 0) return null;
  if (isDisabled) return null;

  return (
    <div className="px-2 py-4 hover:cursor-pointer">
      <div
        className="pb-3 text-lg flex justify-between border-b-[1px] border-b-gray-300"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{node?.value?.name}</span>
        <span className="text-2xl">{isOpen ? "–" : "+"}</span>
      </div>
      <div className={`pt-2 ${isOpen ? "" : "hidden"}`}>
        {searchTerm && !foundTerm
          ? <span className="text-gray-600 pl-8">Sin resultados</span>
          : <CategoryGroup children={node.children} level={0} searchTerm={searchTerm} />
        }
      </div>
    </div>
  );
};

interface Node {
  value: {
    id: number;
    name: string;
  } | null;
  children: Node[];
}

const treefy = (
  categories: { [categoryId: number]: WpCategory },
  groupedCategoriesByParent: { [parentId: number]: WpCategory[] },
  value: WpCategory
): Node => {
  const children = groupedCategoriesByParent[value.id] || [];

  return {
    value: categories[value?.id],
    children: children.map((child) =>
      treefy(categories, groupedCategoriesByParent, child)
    ),
  };
};

const treeSearch = (searchTerm: string, node: Node): boolean => {
  if (node.value?.name.toLowerCase().includes(searchTerm.toLowerCase()))
    return true;

  return node.children.some((child) => treeSearch(searchTerm, child));
}

const SelectCategories = () => {
  const { selectedCategories, biographyData, setBiographyData } =
    useContext(CreateContext);
  const categories = useAppSelector(selectCategories);
  const categoriesById = useAppSelector(selectCategoriesById);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const groupedCategoriesByParent = useMemo(() => {
    return categories?.reduce((acc, category) => {
      if (!category.parent) return acc;

      acc[category.parent] = [...(acc[category.parent] || []), category];

      return acc;
    }, {} as { [parentId: number]: WpCategory[] });
  }, [categories]);

  const tree: Node = useMemo(() => {
    if (
      categories === undefined ||
      groupedCategoriesByParent === undefined ||
      categoriesById === undefined
    )
      return { value: null, children: [] };

    const rootCategories = categories.filter((category) => !category.parent);

    return {
      value: null,
      children: rootCategories.map((category) =>
        treefy(categoriesById, groupedCategoriesByParent, category)
      ),
    };
  }, [categories, categoriesById, groupedCategoriesByParent]);

  return (
    <FlowStep
      nextPath="../confirmar"
      previousPath="../vincular-autores"
      onNext={() => {
        if (!selectedCategories) return true;

        setBiographyData({
          ...biographyData,
          categories: Object.entries(selectedCategories)
            .filter(([_, isSelected]) => isSelected)
            .map(([categoryId, _]) => parseInt(categoryId)),
        });

        return true; // continue to next step.
      }}
    >
      {categories ? (
        <div className="grid gap-8 grid-cols-5 w-full">
          <div className="col-start-1 col-end-3">
            <H1>Categorías</H1>
            <CreateStepper currentStep={2} />
            <p className="mt-10 mb-6 text-base leading-6 text-gray-600">
              Seleccioná las categorías de cada grupo
            </p>
            <div>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <div className="pt-6">
              {tree.children.map((node) => (
                <RootCategory key={node.value?.id} node={node} searchTerm={searchTerm}/>
              ))}
            </div>
          </div>
          <div className="col-start-3 col-end-6 border-l-4 pl-10">
            <div className="fixed">
              <H2>Seleccionadas</H2>
              {categoriesById && (
                <SelectedViewer indexedCategories={categoriesById} />
              )}
            </div>
          </div>
        </div>
      ) : (
        <p>No hay categorías disponibles.</p>
      )}
    </FlowStep>
  );
};

export default SelectCategories;
