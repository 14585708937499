import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import BatchLoadShow from "../Batch/Show/Show";
import IndividualLoadShow from "../Individual/Show/Show";
import { LoadStatus } from "../List/LoadStatusBadge";
import { getLoad } from "lib/backend";
import Spinner from "shared/components/Spinner";
import PageBody from "shared/components/PageBody";

export interface Row {
  subjectName: string;
  wpPostId?: string;
  properties?: { [key: string]: string };
  processedAt: string;
  errors?: string[];
}

interface BaseLoad {
  PK: string;
  CreatedAt: string;
  EntityType: string;
  Status: LoadStatus;
  UploadedBy: string;
}

export interface IndividualLoad extends BaseLoad {
  LoadType: "individual";
  SubjectName: string;
  WpPostId: string;
}

export interface BatchLoad extends BaseLoad {
  LoadType: "batch";
  Filename: string;
  SuccessRows?: Row[];
  ErrorRows?: Row[];
}

export type Load = IndividualLoad | BatchLoad;

const Show = () => {
  const { id } = useParams();
  const query = useQuery([`load-${id}`], () => getLoad(id));
  const data = query.data;

  if (query.isLoading) {
    return (
      <PageBody>
        <div className="absolute flex items-center justify-center w-full h-full">
          <Spinner />
        </div>
      </PageBody>
    );
  }

  if (!data) {
    return null; // TODO: handle error
  }

  const { load, events } = data;

  switch (load?.LoadType) {
    case "individual": {
      return <IndividualLoadShow load={load} events={events} />;
    }
    case "batch": {
      return <BatchLoadShow load={load} events={events} />;
    }
    default:
      console.error("Unhandled load type");
      return null;
  }
};

export default Show;
