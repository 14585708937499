import axios from "axios";
import { SubmitBiographyInput } from "features/Loads/Individual/Create/ConfirmData/ConfirmData";
import { Load } from "features/Loads/Show/Show";

const BASE_URL = process.env.REACT_APP_BACKOFFICE_API_URL;

export interface User {
  PK: string;
  EntityType: string;
  FullName: string;
  Email: string;
  Role: string;
  IsActive: boolean;
  CreatedAt: string;
  UpdatedAt: string;
}

export const listLoads = async (): Promise<Load[]> => {
  const results = await axios.get(`${BASE_URL}/cargas`, {
    headers: {
      Authorization: `Bearer ${localStorage
        .getItem("access_token")
        ?.toString()}`,
    },
  });
  const data = results.data;

  return data;
};

interface FullLoad {
  load: Load;
  events: any[];
}

export const getLoad = async (id?: string): Promise<FullLoad | null> => {
  if (id === undefined) {
    return null;
  }

  const results = await axios.get(`${BASE_URL}/cargas/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage
        .getItem("access_token")
        ?.toString()}`,
    },
  });
  const data = results.data;

  return data;
};

export const createLoad = async ({
  biography,
  newAuthors,
  linkedAuthors,
}: SubmitBiographyInput) => {
  const { sources, ...rest } = biography;
  const { data: response } = await axios.post(
    `${process.env.REACT_APP_BACKOFFICE_API_URL}/cargas`,
    {
      loadType: "individual",
      biography: {
        ...rest,
        meta: {
          source: sources,
          imageUrl:
            "https://diccionario.cedinci.org/wp-content/uploads/2020/09/sello_noimage_cubierta.png",
        },
      },
      newAuthors: Object.values(newAuthors),
      linkedAuthors: Object.values(linkedAuthors),
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage
          .getItem("access_token")
          ?.toString()}`,
      },
    }
  );
  return response;
};

interface CreateBatchLoadInput {
  key: string;
  loadId: string;
}

export const createBatchLoad = async ({
  key,
  loadId,
}: CreateBatchLoadInput) => {
  const { data: response } = await axios.post(
    `${process.env.REACT_APP_BACKOFFICE_API_URL}/cargas`,
    {
      loadType: "batch",
      key,
      loadId,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage
          .getItem("access_token")
          ?.toString()}`,
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const uploadFile = async (file: File, url: string) => {
  await axios.put(url, file);
};

export const createLink = async (
  file: File
): Promise<{ url: string; loadId: string }> => {
  const filename = file.name;

  const results = await axios.post(
    `${BASE_URL}/links`,
    { filename },
    {
      headers: {
        Authorization: `Bearer ${localStorage
          .getItem("access_token")
          ?.toString()}`,
        "Content-Type": "application/json",
      },
    }
  );

  return results.data;
};

export const listUsers = async (): Promise<User[]> => {
  const results = await axios.get(`${BASE_URL}/usuarios`, {
    headers: {
      Authorization: `Bearer ${localStorage
        .getItem("access_token")
        ?.toString()}`,
    },
  });
  const data = results.data;

  return data;
};

export const getUser = async (id?: string): Promise<User | null> => {
  if (id === undefined) {
    return null;
  }

  const results = await axios.get(`${BASE_URL}/usuarios/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage
        .getItem("access_token")
        ?.toString()}`,
    },
  });
  const data = results.data;

  return data;
};

export const editUser = async (id: string | undefined, attributes: any) => {
  if (id === undefined) {
    return null;
  }

  return axios.put(`${BASE_URL}/usuarios/${id}`, attributes, {
    headers: {
      Authorization: `Bearer ${localStorage
        .getItem("access_token")
        ?.toString()}`,
    },
  });
};

interface CreateUserBody {
  FullName: string;
  Email: string;
  Role: string;
  IsActive: boolean;
  TemporaryPassword: string;
}

export const createUser = async (body: CreateUserBody) => {
  return axios.post(`${BASE_URL}/usuarios`, body, {
    headers: {
      Authorization: `Bearer ${localStorage
        .getItem("access_token")
        ?.toString()}`,
    },
  });
};
