import { useContext, useEffect } from "react";
import { AuthContext } from "../AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getTokens, loginUrl } from "lib/cognito";
import { useQuery } from "@tanstack/react-query";

const Cognito = () => {
  const navigate = useNavigate();
  const [searchParams, _setSearchParams] = useSearchParams();
  const code = searchParams.get("code");
  const { setTokens } = useContext(AuthContext)!;
  const tokenQuery = useQuery({
    queryKey: ["code", code],
    queryFn: async () => {
      if (code === null) return;

      return getTokens(code).then((res) => {
        const { data } = res;
    
        setTokens(data.id_token, data.access_token, data.refresh_token);
        navigate("/");
        return res;
      });
    },
    enabled: false
  });

  useEffect(() => {
    tokenQuery.refetch();
  }, [tokenQuery]);

  if (code === null) {
    navigate(loginUrl());
    return <span>Error</span>;
  }

  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      Iniciando sesión...
    </div>
  );
};

export default Cognito;
