import LoadStatusBadge from "features/Loads/List/LoadStatusBadge";
import { IndividualLoad } from "features/Loads/Show/Show";
import { Link } from "react-router-dom";
import PageBody from "shared/components/PageBody";
import { H1, H3 } from "shared/components/headers";

interface Props {
  load: IndividualLoad;
  events: any;
}

const renderEventDescription = (event: any) => {
  switch (event.EventName) {
    case "create_linked_post":
      return (
        <span>
          Post de {event.SubjectName} creado y vinculado en{" "}
          <a className="underline" href={event.WpPostLink}>
            {event.WpPostLink}
          </a>
        </span>
      );

    case "link_post":
      return (
        <span>
          Post de {event.SubjectName} vinculado en{" "}
          <a className="underline" href={event.WpPostLink}>
            {event.WpPostLink}
          </a>
        </span>
      );

    case "create_individual_post":
      return (
        <span>
          Post creado en{" "}
          <a className="underline" href={event.WpPostLink}>
            {event.WpPostLink}
          </a>
        </span>
      );

    default:
      return event.Description;
  }
};

const EventRow = ({ event }: { event: any }) => {
  return (
    <p>
      [{event.CreatedAt}] {renderEventDescription(event)}
    </p>
  );
};

const Show = ({ load, events }: Props) => {
  return (
    <PageBody>
      <header className="pb-5">
        <H1>{load.SubjectName}</H1>
        <p>
          <LoadStatusBadge status={load.Status} /> | {load.PK}
        </p>
      </header>
      <section>
        <div className="flex flex-row items-center justify-start gap-4 py-4">
          <Link
            className="font-semibold text-white bg-indigo-600 border-b-slate-400 px-3 py-2 rounded-md shadow-sm shadow-slate-100"
            to={`${process.env.REACT_APP_DICCIONARIO_WP_URL}/?p=${load.WpPostId}`}
            target="_blank"
          >
            Ver post
          </Link>
          <Link
            className="font-semibold text-white bg-indigo-600 border-b-slate-400 px-3 py-2 rounded-md shadow-sm shadow-slate-100"
            to={`${process.env.REACT_APP_DICCIONARIO_WP_URL}/wp-admin/post.php?post=${load.WpPostId}&action=edit`}
            target="_blank"
          >
            Editar post
          </Link>
        </div>
      </section>
      <section className="flex flex-col gap-10">
        <div className="w-full">
          <H3>Eventos</H3>
          <div className="font-mono bg-gray-300 p-2">
            {[...events]
              .sort(
                (a: any, b: any) =>
                  new Date(b.CreatedAt).getTime() -
                  new Date(a.CreatedAt).getTime()
              )
              .map((event: any) => (
                <EventRow event={event} key={event.SK} />
              ))}
          </div>
        </div>
        <div className="w-full">
          <H3>Errores</H3>
          <div className="p-2">No hay errores</div>
        </div>
      </section>
    </PageBody>
  );
};

export default Show;
