import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const H3 = ({ children }: Props) => {
  return (
    <h3 className="text-lg font-bold leading-7 text-gray-800 mb-2">
      {children}
    </h3>
  );
};

export default H3;
