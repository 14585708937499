import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const H1 = ({ children }: Props) => {
  return (
    <h1 className="text-2xl font-semibold leading-7 text-gray-900 mb-10">
      {children}
    </h1>
  );
};

export default H1;
