import { Link, useSearchParams } from "react-router-dom";

// FIXME: generalize this

const Success = () => {
  const [searchParams] = useSearchParams();
  const postId = searchParams.get("postId");

  return (
    <div className="flex flex-col items-center justify-center h-screen gap-4">
      <div className="text-2xl font-semibold text-indigo-600 mb-10">
        Biografía cargada
      </div>
      <div className="flex flex-row items-center justify-center gap-4">
        <Link
          to="../cargar-datos"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Confirmar
        </Link>
        <Link
          className="font-semibold border-b-gray-400 px-3 py-2 rounded-md shadow-sm shadow-slate-100"
          to={`${process.env.REACT_APP_DICCIONARIO_WP_URL}/?p=${postId}`}
        >
          Ver post
        </Link>
        <Link
          className="font-semibold border-b-gray-400 px-3 py-2 rounded-md shadow-sm shadow-slate-100"
          to={`${process.env.REACT_APP_DICCIONARIO_WP_URL}/wp-admin/post.php?post=${postId}&action=edit`}
        >
          Editar post
        </Link>
      </div>
    </div>
  );
};

export default Success;
