import { CognitoJwtVerifier } from "aws-jwt-verify";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const userPoolId = process.env.REACT_APP_COGNITO_USERPOOL_ID ?? "";
const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID ?? "";

export const loginUrl = () => {
  const url = new URL(
    `https://${process.env.REACT_APP_COGNITO_DOMAIN}.auth.us-east-1.amazoncognito.com/oauth2/authorize`
  );

  url.searchParams.set("client_id", clientId);
  url.searchParams.set("response_type", "code");
  url.searchParams.set(
    "redirect_uri",
    `${window.location.origin}/oauth2/cognito`
  );

  // Need to specify scope value as this to avoid URL encoding + signs
  return `${url.href}&scope=email+openid`;
};

export const logoutUrl = () => {
  const url = new URL(
    `https://${process.env.REACT_APP_COGNITO_DOMAIN}.auth.us-east-1.amazoncognito.com/logout`
  );

  url.searchParams.set("client_id", clientId);
  url.searchParams.set("response_type", "code");
  url.searchParams.set(
    "redirect_uri",
    `${window.location.origin}/oauth2/cognito`
  );

  // Need to specify scope value as this to avoid URL encoding + signs
  return url;
};

export const getTokens = async (code: string) => {
  const url = new URL(
    `https://${process.env.REACT_APP_COGNITO_DOMAIN}.auth.us-east-1.amazoncognito.com/oauth2/token`
  );

  const res = await axios.post(url.href, {
    client_id: clientId,
    grant_type: "authorization_code",
    code: code,
    redirect_uri: `${window.location.origin}/oauth2/cognito`,
  },
  {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });

  return res;
}

export const refreshTokens = async (refreshToken: string) => {
  const url = new URL(
    `https://${process.env.REACT_APP_COGNITO_DOMAIN}.auth.us-east-1.amazoncognito.com/oauth2/token`
  );

  const res = await axios.post(url.href, {
    client_id: clientId,
    grant_type: "refresh_token",
    refresh_token: refreshToken,
  }, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });

  return res;
};

export const verifyToken = async (token: string, tokenUse: "id" | "access") => {
  const verifier = CognitoJwtVerifier.create({
    userPoolId,
    clientId,
    tokenUse: tokenUse,
  });

  try {
    await verifier.verify(token);

    return true;
  } catch (e) {
    return false;
  }
};

const UNDEFINED_JWT_ERROR = "Token de autenticación inválido.";

export const minutesUntilTokenExpiration = (jwt: string) => {
  const decodedJwt = jwtDecode(jwt);

  if (decodedJwt === undefined) throw new Error(UNDEFINED_JWT_ERROR);
  if (decodedJwt.exp === undefined) return Number.NEGATIVE_INFINITY;

  const seconds = decodedJwt.exp - Math.floor(Date.now() / 1000);

  return seconds / 60;
}