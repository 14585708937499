import { Link } from "react-router-dom";
import { UserIcon, TableCellsIcon } from "@heroicons/react/24/outline";

import {
  ArrowTopRightOnSquareIcon,
  DocumentMagnifyingGlassIcon,
  DocumentPlusIcon,
  UserPlusIcon,
} from "@heroicons/react/20/solid";

import PageBody from "../../../shared/components/PageBody";
import LoadStatusBadge from "./LoadStatusBadge";
import { Load } from "../Show/Show";
import { listLoads } from "lib/backend";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { H3 } from "shared/components/headers";
import SkeletonList from "shared/components/SkeletonList";

interface LoadRowProps {
  load: Load;
}

const LoadRow = ({ load }: LoadRowProps) => {
  const createdAt = new Date(load.CreatedAt);

  return (
    <tr className="text-center hover:bg-slate-100 border-b-2 border-slate-50">
      <td className="p-4 flex justify-center">
        {load.LoadType === "individual" ? (
          <UserIcon width="2rem" title="Carga individual" />
        ) : (
          <TableCellsIcon width="2rem" title="Carga en lote" />
        )}
      </td>
      <td>
        {load.LoadType === "individual" ? load.SubjectName : load.Filename}
      </td>
      <td>{`${createdAt.toLocaleTimeString()} ${createdAt.toLocaleDateString()}`}</td>
      <td>
        <LoadStatusBadge status={load.Status} />
      </td>
      {load.LoadType === "individual" ? (
        <td>
          <div className="flex flex-row justify-center items-center gap-5">
            <Link to={`/cargas/${load.PK}`} title="Ver detalles de carga">
              <DocumentMagnifyingGlassIcon width="2rem" />
            </Link>
            {load.WpPostId && (
              <Link
                to={`${process.env.REACT_APP_DICCIONARIO_WP_URL}/?post=${load.WpPostId}`}
                target="_blank"
                title="Abrir en WordPress"
              >
                <ArrowTopRightOnSquareIcon width="2rem" />
              </Link>
            )}
          </div>
        </td>
      ) : (
        <td>
          <div className="flex flex-row justify-center items-center gap-5">
            <Link to={`/cargas/${load.PK}`} title="Ver detalles de carga">
              <DocumentMagnifyingGlassIcon width="2rem" />
            </Link>
          </div>
        </td>
      )}
    </tr>
  );
};

const List = () => {
  const query = useQuery({
    queryKey: ["listLoads"],
    queryFn: () => listLoads(),
    enabled: false,
  });

  useEffect(() => {
    query.refetch();
  }, [query]);

  return (
    <PageBody>
      <header className="mb-4">
        <h1 className="text-2xl font-semibold leading-7 text-gray-900 my-10">
          Cargas
        </h1>
        <div className="flex flex-row gap-4 pb-4">
          <Link
            className="flex flex-row items-center gap-2 rounded-md bg-green-600 px-3 py-2 text-medium font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            to={"individual/nueva"}
          >
            <UserPlusIcon className="h-8" /> Biografía
          </Link>
          <Link
            className="flex flex-row items-center gap-2 rounded-md bg-green-600 px-3 py-2 text-medium font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            to={"lote/nueva"}
          >
            <DocumentPlusIcon className="h-8" /> Lote
          </Link>
        </div>
      </header>
      <div>
        <div className="mb-8 flex flex-row justify-between items-end gap-5">
          <H3>Historial de cargas</H3>
          <div className="flex flex-row gap-4">
            <div>
              Desde: <input type="date" />
            </div>
            <div>
              Hasta: <input type="date" />
            </div>
          </div>
        </div>
        <table className="table-auto min-w-full">
          <thead className="border-b-2 border-b-slate-200">
            <tr>
              <th className="p-4">Tipo</th>
              <th>ID</th>
              <th>Fecha</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          {query.isLoading ? (
            <tbody>
              <tr>
                <td colSpan={6}>
                  <SkeletonList />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {query.data?.map((load) => (
                <LoadRow load={load} key={load.PK} />
              ))}
            </tbody>
          )}
        </table>
      </div>
    </PageBody>
  );
};

export default List;
