export interface AuthorDetected {
  index: number;
  input: string;
  fullName: string | null;
  groups: string[];
}

const DETECT_AUTHOR_IN_BIOGRAPHY_REGEX = /(([A-Z][^►\s]*\s?)+)?►(<\/strong>)?/g;
const EXTRACT_AUTHOR_FULL_NAME_REGEX = /(([A-Z][^►<\s]*\s?)+)/;

export const detectAuthors = (biography: string): AuthorDetected[] => {
  const regex = new RegExp(DETECT_AUTHOR_IN_BIOGRAPHY_REGEX);

  let authorsDetected: RegExpExecArray[] = [];
  let match: RegExpExecArray | null;
  while ((match = regex.exec(biography)) != null)
    authorsDetected.unshift(match); // .unshift is needed as we'll be modifying bio by indexes

  return authorsDetected.map((author) => {
    const index = author.index;
    const input: string = author[0];
    const authorMatch = input.match(EXTRACT_AUTHOR_FULL_NAME_REGEX);

    const authorFullName: string | null =
      authorMatch && authorMatch[0] ? authorMatch[0].trim() : null;

    const authorDetected = {
      index: index,
      input: input,
      fullName: authorFullName,
      groups: author.slice(1),
    };

    return authorDetected;
  });
};

export const paintAuthorsInBiography = (
  biography: string | undefined,
  authorsDetected: AuthorDetected[] | undefined,
  highlightAuthor?: string
): string | undefined => {
  if (!biography || !authorsDetected || authorsDetected.length === 0)
    return biography;

  try {
    authorsDetected.reduce((prev, curr) => {
      if (curr.index < prev.index) throw new Error("Invalid author indexes");
      return curr;
    });
  } catch (error) {
    console.warn("Author indexes are not in descending order.", error);
  }

  return authorsDetected.reduce<string>(
    (biography, author) =>
      biography.substring(0, author.index) +
      `<span class="author ${
        author.fullName === highlightAuthor ? "highlight" : ""
      }">${author.fullName}</span>` +
      biography.substring(author.index + author.input.length),
    biography
  );
};
