import React, { useContext } from "react";
import BiographyPreview from "../shared/BiographyPreview";
import { CreateContext } from "../Create";
import { BiographyData } from "../types";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import CreateStepper from "../shared/Stepper";
import FlowStep from "../shared/FlowStep";
import RadioSelector from "./RadioSelector";
import type { ExistingAuthorLink, NewAuthorLink } from "../LinkAuthors/utils";
import { createLoad } from "lib/backend";
import SelectedViewer from "../SelectCategories/SelectedViewer";
import { H3 } from "shared/components/headers";
import { useAppSelector } from "hooks";
import { selectCategoriesById } from "reducers/categoriesSlice";
import { WpCategory } from "lib/models/WpCategory";

export interface SubmitBiographyInput {
  biography: BiographyData;
  newAuthors: { [subjectName: string]: NewAuthorLink };
  linkedAuthors: { [subjectName: string]: ExistingAuthorLink };
}

interface PostStatusProps {
  biographyData: BiographyData;
  setBiographyData: (biography: BiographyData) => void;
}

interface SummaryBoxProps {
  title: string;
  children: React.ReactNode;
}

const SummaryBox = ({ title, children }: SummaryBoxProps) => {
  return (
    <div className="py-4">
      <div className="border-b">
        <H3>{title}</H3>
      </div>
      <div className="px-2 py-4">{children}</div>
    </div>
  );
};

const PostStatus = ({ biographyData, setBiographyData }: PostStatusProps) => {
  return (
    <SummaryBox title={"Estado publicación"}>
      <RadioSelector
        options={[
          {
            value: "draft",
            label: "Borrador",
          },
          {
            value: "publish",
            label: "Publicar",
          },
        ]}
        onOptionSelected={(option: string) => {
          setBiographyData({ ...biographyData, status: option });
        }}
      />
    </SummaryBox>
  );
};

interface BiographyCategoriesProps {
  indexedCategories?: { [categoryId: string]: WpCategory };
}

const BiographyCategories = ({
  indexedCategories,
}: BiographyCategoriesProps) => {
  return (
    <SummaryBox title={"Categorías"}>
      {indexedCategories && (
        <SelectedViewer indexedCategories={indexedCategories} />
      )}
    </SummaryBox>
  );
};

const ConfirmData = () => {
  const navigate = useNavigate();
  const {
    biographyData,
    setBiographyData,
    setAuthorsDetected,
    linkedAuthors,
    setLinkedAuthors,
    newAuthors,
    setNewAuthors,
  } = useContext(CreateContext);
  const indexedCategories = useAppSelector(selectCategoriesById);

  // TODO: handle loading and error states
  const { mutate, isLoading } = useMutation(createLoad, {
    onSuccess: (data) => {
      setBiographyData({});
      setAuthorsDetected([]);
      setLinkedAuthors({});
      setNewAuthors({});

      navigate(`/cargas/${data.loadId}`);
    },
    onError: (req: any) => {
      const { response } = req;
      const { data } = response;

      console.error(req);

      alert(data.message);
    },
  });

  return (
    <FlowStep
      previousPath="../categorias"
      nextPath="#"
      nextLabel="Crear"
      nextDisabled={isLoading}
      onNext={() => {
        mutate({ biography: biographyData, newAuthors, linkedAuthors });
      }}
    >
      <div className="flex justify-left">
        <div className="grid gap-8 grid-cols-5 w-full">
          <div className="space-y-12 col-start-1 col-end-3">
            <div className="pb-12">
              <h2 className="text-2xl font-semibold leading-7 text-gray-900 mb-10">
                Confirmar
              </h2>
              <CreateStepper currentStep={3} />
              <p className="my-10 text-base leading-6 text-gray-600">
                Sumario de los datos de la biografía.
              </p>
              <PostStatus
                biographyData={biographyData}
                setBiographyData={setBiographyData}
              />
              <BiographyCategories indexedCategories={indexedCategories} />
            </div>
          </div>
          <div className="col-start-3 col-end-6 border-l-4 pl-10">
            <BiographyPreview {...biographyData} />
          </div>
        </div>
      </div>
    </FlowStep>
  );
};

export default ConfirmData;
