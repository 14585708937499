import { useMutation, useQuery } from "@tanstack/react-query";
import PageBody from "../../../shared/components/PageBody";
import { editUser, getUser } from "lib/backend";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Spinner from "shared/components/Spinner";

const Edit = () => {
  const { id } = useParams();

  const [isActive, setIsActive] = useState(false);
  const [role, setRole] = useState<string>();

  const query = useQuery([`user-${id}`], () => getUser(id));
  const mutation = useMutation(
    ["userEdit"],
    () => editUser(id, { IsActive: isActive, Role: role }),
    {
      onSuccess: (data) => {
        // TODO: avisar que se actualizo okas
        console.log("SUCCESS");
      },
      onError: (error) => {
        // TODO
      },
    }
  );

  useEffect(() => {
    if (!query.data) return;

    const user = query.data;

    setRole(user.Role);
    setIsActive(user.IsActive);
  }, [query.data]);

  if (query.isLoading) {
    return (
      <PageBody>
        <div className="absolute flex items-center justify-center w-full h-full">
          <Spinner />
        </div>
      </PageBody>
    );
  }
  if (query.isError) return <div>Error</div>;
  if (query.data === null) return <div>No se encontró el usuario</div>;

  const user = query.data;

  return (
    <PageBody>
      <header className="mb-4">
        <h1 className="text-2xl font-semibold leading-7 text-gray-900 my-10">
          {user.Email}
        </h1>
        <p className="text-2xl">Edición del usuario</p>
      </header>
      <div className="grid grid-cols-1 items-end gap-x-6 gap-y-8 sm:grid-cols-1 md:grid-cols-2">
        <div className="sm:col-span-1">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Nombre
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="first-name"
              id="first-name"
              autoComplete="given-name"
              value={user.FullName}
              disabled
              className="block w-full bg-gray-300 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="sm:col-span-1">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Email
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="email"
              id="email"
              autoComplete="given-name"
              value={user.Email}
              disabled
              className="block w-full bg-gray-300 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="sm:col-span-1">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Activo
          </label>
          <div className="mt-2">
            <input
              type="checkbox"
              name="first-name"
              id="first-name"
              checked={isActive}
              onChange={() => setIsActive(!isActive)}
              autoComplete="given-name"
              className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="sm:col-span-1">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Rol
          </label>
          <div className="mt-2">
            <select
              name="first-name"
              id="first-name"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
              <option value="EDITOR">Editor</option>
              <option value="ADMIN">Administrador</option>
            </select>
          </div>
        </div>
      </div>
      <div className="mt-4 flex justify-end gap-3">
        <button
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => {
            mutation.mutate();
          }}
        >
          Guardar
        </button>
        <div className="border-l-2 border-l-slate-200"></div>
        <button className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
          Borrar
        </button>
      </div>
    </PageBody>
  );
};

export default Edit;
