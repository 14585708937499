import { useContext, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import QuillEditor from "quill";

import "react-quill/dist/quill.snow.css";
import BiographyPreview from "../shared/BiographyPreview";
import { detectAuthors } from "../shared/utils";
import { CreateContext } from "../Create";
import Select from "react-select";
import CreateStepper from "../shared/Stepper";
import FlowStep from "../shared/FlowStep";
import { H1 } from "shared/components/headers";
import { sanitizeBiographyBody } from "lib/utils";
import { selectCategories } from "reducers/categoriesSlice";
import { store } from "store";
import { useAppSelector } from "hooks";

const AUTHOR_CATEGORY_ID: number = 250;

interface BiographyNameProps {
  firstName?: string;
  lastName?: string;
  pseudonyms?: string;
  warNames?: string;
  setFirstName: (firstName: string) => void;
  setLastName: (lastName: string) => void;
  setPseudonyms: (pseudonyms: string) => void;
  setWarNames: (warNames: string) => void;
}

const BiographyName = ({
  firstName,
  lastName,
  pseudonyms,
  warNames,
  setFirstName,
  setLastName,
  setPseudonyms,
  setWarNames,
}: BiographyNameProps) => {
  const [rawLastName, setRawLastName] = useState<string>();

  return (
    <>
      <div className="mt-7 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Nombre*
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="first-name"
              id="first-name"
              autoComplete="given-name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="last-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Apellido*
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="last-name"
              id="last-name"
              autoComplete="family-name"
              value={lastName}
              onChange={(e) => {
                console.log(e);

                const newValue = e.target.value;
                const isLastNameEmpty = lastName === undefined || lastName === "";
                const allCaps = !/[a-z]/.test(newValue);
                const shouldAutoCapitalize = isLastNameEmpty && allCaps && newValue.length > 1

                if (shouldAutoCapitalize) {
                  const autoCapitalizedValue = newValue
                    .split(/\s/)
                    .map((val: string) => val.charAt(0) + val.substring(1).toLowerCase())
                    .join(" ");

                  setLastName(autoCapitalizedValue);
                  setRawLastName(newValue);
                } else {
                  setLastName(newValue);
                  setRawLastName(undefined);
                }
              }}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          {rawLastName && (
            <button
              className="cursor-pointer underline text-xs text-blue-500"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setLastName(rawLastName);
              }}
            >
              Mantener mayúsculas
            </button>
          )}
        </div>
      </div>

      <div className="mt-7 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <label
            htmlFor="pseudonyms"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Seudónimos
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="pseudonyms"
              id="pseudonyms"
              autoComplete="pseudonyms"
              value={pseudonyms}
              onChange={(e) => setPseudonyms(e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="last-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Nombres de guerra
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="war-names"
              id="war-names"
              autoComplete="war-names"
              value={warNames}
              onChange={(e) => setWarNames(e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>
    </>
  );
};

const LoadData = () => {
  const {
    authorsDetected,
    setAuthorsDetected,
    biographyData,
    setBiographyData,
  } = useContext(CreateContext);
  const categories = useAppSelector(selectCategories);

  const [firstName, setFirstName] = useState<string>(
    biographyData.firstName ?? ""
  );
  const [lastName, setLastName] = useState<string>(
    biographyData.lastName ?? ""
  );
  const [pseudonyms, setPseudonyms] = useState<string>(
    biographyData.pseudonyms ?? ""
  );
  const [warNames, setWarNames] = useState<string>(
    biographyData.warNames ?? ""
  );
  const [generalData, setGeneralData] = useState<string>(
    biographyData.generalData ?? ""
  );
  const [briefDescription, setBriefDescription] = useState<string>(
    biographyData.briefDescription ?? ""
  );
  const [body, setBody] = useState<string>(biographyData.body ?? "");
  const [authors, setAuthors] = useState<string[]>(biographyData.authors ?? []);
  const [sources, setSources] = useState<string>(biographyData.sources ?? ""); // TODO: Categorize sources
  const [work, setWork] = useState<string>(biographyData.work ?? "");

  const authorsCategories = (categories || []).filter(
    (category) => category.parent === AUTHOR_CATEGORY_ID
  );

  const biographyEditorRef = useRef<ReactQuill & { editor: QuillEditor }>(null);
  const sourcesEditorRef = useRef<ReactQuill & { editor: QuillEditor }>(null);
  const workEditorRef = useRef<ReactQuill & { editor: QuillEditor }>(null);

  useEffect(() => {
    biographyEditorRef.current?.editor.root.setAttribute("spellcheck", "false");
  }, []);

  useEffect(() => {
    if (body) {
      const authorsDetected = detectAuthors(body);
      setAuthorsDetected(authorsDetected);
    }
  }, [setAuthorsDetected, body]);

  const optionAuthors = authorsCategories
    .sort((a, b) => a['name'].localeCompare(b['name']))
    .map((category) => ({
      value: category["name"],
      label: category["name"],
    }));

  return (
    <FlowStep
      nextPath="./vincular-autores"
      onNext={() =>
        setBiographyData({
          firstName,
          lastName,
          pseudonyms,
          warNames,
          generalData,
          briefDescription,
          body: sanitizeBiographyBody(body),
          sources: sources,
          authors: authors,
          work: work,
        })
      }
      nextDisabled={
        !(authors && authors.length > 0 && (!!firstName || !!lastName))
      }
    >
      <div className="grid gap-8 grid-cols-5 w-full">
        <form className="col-start-1 col-end-3">
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
              <H1>Carga Nueva</H1>
              <CreateStepper currentStep={0} />
              <p className="mt-10 text-base leading-6 text-gray-600">
                Ingresá los datos para crear una biografía nueva.
              </p>

              <BiographyName
                firstName={firstName}
                lastName={lastName}
                pseudonyms={pseudonyms}
                warNames={warNames}
                setFirstName={setFirstName}
                setLastName={setLastName}
                setPseudonyms={setPseudonyms}
                setWarNames={setWarNames}
              />

              <div className="mt-10 col-span-full">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Datos Generales
                </label>
                <div className="mt-2">
                  <textarea
                    id="about"
                    name="about"
                    rows={3}
                    value={generalData}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setGeneralData(e.target.value)}
                    defaultValue={""}
                  />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-600">
                  Texto explicativo.
                </p>
              </div>

              <div className="mt-10 col-span-full">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Descripción Breve
                </label>
                <div className="mt-2">
                  <textarea
                    id="about"
                    name="about"
                    rows={3}
                    value={briefDescription}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                    onChange={(e) => setBriefDescription(e.target.value)}
                  />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-600">
                  Texto explicativo.
                </p>
              </div>

              <div className="mt-10 col-span-full">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Biografía
                </label>
                <div className="mt-2">
                  <ReactQuill
                    id="about"
                    ref={biographyEditorRef}
                    theme="snow"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                    value={body}
                    onChange={setBody}
                  />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-600">
                  Texto explicativo.
                </p>
              </div>

              <div className="mt-10 col-span-full border-b border-gray-900/10 pb-6">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Obras
                </label>
                <div className="mt-2">
                  <ReactQuill
                    id="work"
                    ref={workEditorRef}
                    theme="snow"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                    value={work}
                    onChange={setWork}
                  />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-600">
                  Texto explicativo.
                </p>
              </div>

              <div className="mt-10 col-span-full">
                <label
                  htmlFor="author"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Autor/es*
                </label>
                <div className="mt-2">
                  <Select
                    id="author"
                    name="author"
                    isMulti
                    value={authors.map((label) => ({
                      label: label,
                      value: label,
                    }))}
                    options={optionAuthors}
                    onChange={(newValue) =>
                      setAuthors(newValue.map<string>((s) => s.value))
                    }
                  />
                </div>
              </div>

              <div className="mt-10 col-span-full">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Fuentes
                </label>
                <div className="mt-2">
                  <ReactQuill
                    id="sources"
                    ref={sourcesEditorRef}
                    theme="snow"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                    value={sources}
                    onChange={setSources}
                  />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-600">
                  Texto explicativo.
                </p>
              </div>
            </div>
          </div>
        </form>
        <div className="col-start-3 col-end-6 border-l-4 pl-10">
          <BiographyPreview
            firstName={firstName}
            lastName={lastName}
            pseudonyms={pseudonyms}
            warNames={warNames}
            generalData={generalData}
            briefDescription={briefDescription}
            body={body}
            authorsDetected={authorsDetected}
            authors={authors}
            work={work}
          />
        </div>
      </div>
    </FlowStep>
  );
};

export default LoadData;
