import { useState } from "react";
import { AuthorFullName } from "./utils";

interface Props {
  fullName: AuthorFullName;
  onAuthorConfirmed: (firstName: string, lastName: string) => void;
}

const AuthorCreate = ({ fullName, onAuthorConfirmed }: Props) => {
  const [firstName, setFirstName] = useState<string>(
    fullName?.split(" ")[0] ?? ""
  );
  const [lastName, setLastName] = useState<string>(
    fullName?.split(" ").slice(1).join(" ") ?? ""
  );

  return (
    <div className="flex-1 p-6 bg-slate-100">
      <h3 className="block text-base font-medium leading-6 text-gray-900 mb-4 pl-2 border-l-2 border-l-black">
        Crear nueva entrada
      </h3>
      <form className="space-y-12">
        <div className="grid grid-cols-1 items-end gap-x-6 gap-y-8 sm:grid-cols-5">
          <div className="sm:col-span-2">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Nombre
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="first-name"
                id="first-name"
                autoComplete="given-name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Apellido
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="last-name"
                id="last-name"
                autoComplete="family-name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <button
            type="submit"
            disabled={!firstName && !lastName}
            className="sm:col-span-1 max-h-10 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() =>
              firstName && lastName && onAuthorConfirmed(firstName, lastName)
            }
          >
            Crear
          </button>
        </div>
      </form>
    </div>
  );
};

export default AuthorCreate;
