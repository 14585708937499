export enum LoadStatus {
  STARTED = "STARTED",
  FINISHED = "FINISHED",
  FAILED = "FAILED",
}

const STATUS_DATA = {
  [LoadStatus.STARTED]: {
    label: "En proceso",
    bgColor: "border-sky-500",
    textColor: "text-sky-500",
  },
  [LoadStatus.FINISHED]: {
    label: "Cargado",
    bgColor: "border-green-600",
    textColor: "text-green-600",
  },
  [LoadStatus.FAILED]: {
    label: "Error",
    bgColor: "border-red-700",
    textColor: "text-red-700",
  },
};

interface Props {
  status: LoadStatus;
}

const LoadStatusBadge = ({ status }: Props) => {
  if (status === undefined) return null;

  return (
    <span
      className={`rounded-xl p-2 text-xs font-bold border-2 ${STATUS_DATA[status]["bgColor"]} ${STATUS_DATA[status]["textColor"]}`}
    >
      {STATUS_DATA[status]["label"]}
    </span>
  );
};

export default LoadStatusBadge;
