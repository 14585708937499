import { ReactNode, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import styles from "./SplashScreen.module.css";
import { set } from "reducers/categoriesSlice";
import { useAppDispatch } from "hooks";
import { listCategories } from "lib/wp_client";
import { getAll, save, stores } from "lib/db";
import { WpCategory } from "lib/models/WpCategory";

const CATEGORIES_UPDATED_AT = "categories_updated_at";
const EXPIRE_CATEGORIES_AFTER_HOURS = 12 * 60 * 60 * 1000;

interface SplashScreenProps {
  children: ReactNode;
}

const useCategoriesQuery = (setCategoriesLoaded: (amount: number) => void) => {
  return useQuery({
    queryKey: ['wpPostCategories'],
    queryFn: async () => {
      let page = 1;
      let categories = [] as WpCategory[];

      while (true) {
        const fetchedCategories = await listCategories(page) ?? [];

        if (fetchedCategories.length <= 0) break;

        categories = [...categories, ...fetchedCategories];

        setCategoriesLoaded(categories.length);

        page++;
      }

      return categories;
    },
    enabled: false
  });
};

const LOADING_DATA = "Cargando datos de WordPress...";
const STORING_CACHE = "Almacenando datos en cache...";

const SplashScreen = ({ children }: SplashScreenProps): JSX.Element => {
  const [message, setMessage] = useState<string>(LOADING_DATA);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [categoriesLoaded, setCategoriesLoaded] = useState<number>(0);
  const dispatch = useAppDispatch();

  const { refetch: fetchCategories } = useCategoriesQuery(setCategoriesLoaded);

  useEffect(() => {
    getAll(stores.categories.name)
      .then(async (cachedCategories) => {
        const localCategoriesUpdatedAt = localStorage.getItem(CATEGORIES_UPDATED_AT);
        const categoriesUpdatedAt = localCategoriesUpdatedAt !== null
          ? new Date(parseInt(localCategoriesUpdatedAt))
          : null;

        if (cachedCategories.length > 0
            && categoriesUpdatedAt
            && categoriesUpdatedAt.getTime() > Date.now() - EXPIRE_CATEGORIES_AFTER_HOURS
        ) {
          dispatch(set(cachedCategories));
          setIsDataLoaded(true);
        } else {
          const { data: fetchedCategories } = await fetchCategories();;

          if (fetchedCategories === undefined) throw new Error("Problema al cargar categorías.");

          setMessage(STORING_CACHE);

          await Promise.all(
              fetchedCategories.map((category) => save(stores.categories.name, category))
          );

          localStorage.setItem(CATEGORIES_UPDATED_AT, Date.now().toString());

          dispatch(set(fetchedCategories));
          setIsDataLoaded(true);
        }
      });
  }, [dispatch, setIsDataLoaded, fetchCategories]);

  return isDataLoaded ? (
    <>{children}</>
  ) : (
    <>
      <div
        style={{
          backgroundColor: "#f0f0f0",
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span className={styles.loader}></span>
        <p className="pb-4">{message}</p>
        <p className="text-gray-600 font-thin">
          {categoriesLoaded}{" "}
          categorias
        </p>
      </div>
    </>
  );
};

export default SplashScreen;
