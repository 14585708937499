import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { AuthorFullName } from "./utils";
import { useMemo, useState } from "react";
import { similarity } from "lib/utils";
import Spinner from "shared/components/Spinner";

interface Props {
  fullName: AuthorFullName;
  onAuthorSelected: (option: any) => void;
}

const useAuthorOptions = (author: AuthorFullName) => {
  return useQuery({
    queryKey: [author],
    queryFn: async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_DICCIONARIO_WP_URL}/wp-json/wp/v2/posts`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            // status: ["publish", "draft", "future"].join(","),
            status: ["publish"].join(","),
            search: author,
            orderby: "relevance",
            order: "desc"
          },
        }
      );

      return data;
    },
  });
};

const AuthorSearch = ({ fullName, onAuthorSelected }: Props) => {
  const [searchName, setSearchName] = useState<string>(fullName ?? "");
  // TODO: handle loading and error states
  const { data, isLoading } = useAuthorOptions(searchName);
  const scores = useMemo(() => {
    return data?.reduce((prev: any, curr: any) => {
      const names = searchName.split(" ");

      let maxScore = 0;
      for (const i in names) {
        const candidate = names
          .slice(parseInt(i))
          .concat(names.slice(0, parseInt(i)))
          .join(" ")
          .toLowerCase();
        const score = similarity(candidate, curr["title"]["rendered"]);

        if (maxScore < score) maxScore = score;
      }

      return { ...prev, [curr["id"]]: maxScore };
    }, {});
  }, [data, searchName]);

  return (
    <div className="flex-1 px-1">
      <h3 className="block text-base font-medium leading-6 text-gray-900 mb-4 pl-2 border-l-2 border-l-black">
        Vincular con entrada existente
      </h3>
      <div className="sm:col-span-2">
        <label
          htmlFor="full-name"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Nombre completo
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="full-name"
            id="full-name"
            autoComplete="family-name"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="pt-2">
        {isLoading && (
          <span className="w-full flex items-center justify-center">
            <Spinner width={12} height={12} />
          </span>
        )}
        {!isLoading && data ? (
          data
            .sort((o1: any, o2: any) => scores[o2["id"]] - scores[o1["id"]])
            .map((option: any) => (
              <div
                key={option["title"]["rendered"]}
                className="border-b last:border-b-0 py-1 px-2 border-b-slate-200 hover:bg-slate-200 hover:cursor-pointer"
                onClick={() => onAuthorSelected(option)}
              >
                <span>{option["title"]["rendered"]}</span>
              </div>
            ))
        ) : (
          <p>Sin resultados</p>
        )}
      </div>
    </div>
  );
};

export default AuthorSearch;
