import "./App.css";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "react-quill/dist/quill.snow.css";

import UserList from "./features/Users/List/List";
import UserEdit from "./features/Users/Edit/Edit";
import UserCreate from "./features/Users/Create/Create";
import Sidebar from "./features/Sidebar/Sidebar";
import Oauth from "./features/Oauth/Oauth";

import { AuthContextProvider } from "./features/Oauth/AuthContext";
import Loads from "features/Loads/Loads";
import SplashScreen from "./features/SplashScreen/SplashScreen";
import NeedsAuth from "./features/Oauth/NeedsAuth";

const AuthenticatedApp = () => {
  return (
    <NeedsAuth>
      <SplashScreen>
        <Sidebar />
        <div className="grow">
          <Routes>
            <Route path="/" element={<Navigate to={"/cargas/"} />} />
            <Route path="/cargas/*" element={<Loads />} />
            <Route path="/usuarios/" element={<UserList />} />
            <Route path="/usuarios/nuevo" element={<UserCreate />} />
            <Route path="/usuarios/:id" element={<UserEdit />} />
          </Routes>
        </div>
      </SplashScreen>
    </NeedsAuth>
  );
};

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <BrowserRouter>
          <div className="flex flex-row w-full">
            <Routes>
              <Route path="/oauth2/*" element={<Oauth />} />
              <Route path="/*" element={<AuthenticatedApp />} />
            </Routes>
          </div>
        </BrowserRouter>
      </AuthContextProvider>
    </QueryClientProvider>
  );
}

export default App;
