export interface Step {
  title: string;
  description: string;
}

interface StepProps {
  title: string;
  description: string;
  currentStep: number;
  index: number;
}

const SelectedStep = ({ index, title, currentStep: selectedStep, description }: StepProps) => (
  <li className="flex items-baseline text-blue-600 dark:text-blue-500 space-x-2.5">
    <span className="flex items-center justify-center w-8 h-8 border border-blue-600 border-2 rounded-full shrink-0 dark:border-blue-500">
      {index + 1}
    </span>
    <span>
      <h3 className="font-medium leading-tight">{title}</h3>
    </span>
  </li>
);

const IdleStep = ({ index, title, currentStep, description }: StepProps) => (
  <li className="flex items-baseline text-gray-500 dark:text-gray-400 space-x-2.5">
    <span className={`flex items-center justify-center w-8 h-8 border rounded-full shrink-0 ${currentStep > index ? "text-white border-blue-600 bg-blue-600 " : "border-gray-500 dark:border-gray-400"}`}>
      {index + 1}
    </span>
    {currentStep < index && (
      <span>
        <h3 className="font-medium leading-tight text-base">{title}</h3>
      </span>
    )}
  </li>
);

interface StepperProps {
  steps: Step[];
  currentStep: number;
}

export const Stepper = ({ steps, currentStep }: StepperProps) => {
  return (
    <ol className="items-center w-full flex flex-wrap gap-x-4 gap-y-2">
      {steps.map((step, index) =>
        index === currentStep ? (
          <SelectedStep key={step.title} index={index} currentStep={currentStep} {...step} />
        ) : (
          <IdleStep key={step.title} index={index} currentStep={currentStep} {...step} />
        )
      )}
    </ol>
  );
};

interface CreateStepperProps {
  currentStep: number;
}

const CreateStepper = ({ currentStep }: CreateStepperProps) => {
  const steps: Step[] = [
    { title: "Cargar datos", description: "Cargar los datos del biografiado." },
    {
      title: "Vincular autores",
      description: "Vincular los autores mencionados en la biografía.",
    },
    {
      title: "Categorías",
      description: "Seleccionar categorías de la biografía.",
    },
    { title: "Revisión", description: "Revisión de la información ingresada." },
  ];

  return <Stepper steps={steps} currentStep={currentStep} />;
};

export default CreateStepper;
