export const capitalize = (str: string) => {
  if (str === "") return "";

  return str
    .split(/\s+/)
    .map((s) => {
      if (s.length === 0) return "";

      return s[0].toUpperCase() + s.substring(1).toLowerCase();
    })
    .join(" ");
};

const LAST_AND_FIRST_NAME_REGEX = /(.*)+,\s(.*)/;

export const nameToObject = (
  input: string
): { firstName: string; lastName: string } => {
  const match = LAST_AND_FIRST_NAME_REGEX.exec(input);

  if (match === null || match.length < 2)
    throw new TypeError("First or last name not recognized");

  return {
    firstName: capitalize(match[2]),
    lastName: capitalize(match[1]),
  };
};
