import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline";
import { AuthContext } from "features/Oauth/AuthContext";
import { logoutUrl } from "lib/cognito";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

interface SidebarElementProps {
  label: string;
  to: string;
}

const SidebarElement = ({ label, to }: SidebarElementProps) => {
  return (
    <Link to={to}>
      <li className="text-lg hover:bg-blue-100 hover:text-blue-700 px-3 py-5 rounded-lg">
        {label}
      </li>
    </Link>
  );
};

const SidebarHeader = () => {
  return (
    <div className="px-4 mb-4 w-full py-10 text-3xl">
      <h2 className="font-serif">Backoffice</h2>
    </div>
  );
};

const SidebarNav = () => {
  return (
    <div className="grow">
      <div>
        <h3 className="uppercase text-slate-400 mb-2">Cargas</h3>
        <ul>
          <SidebarElement label={"Cargas"} to={"/cargas/"} />
        </ul>
      </div>
      <div>
        <h3 className="uppercase text-slate-400 mt-4 mb-2">Admin</h3>
        <ul>
          <SidebarElement label={"Usuarios"} to={"/usuarios"} />
          {/* <SidebarElement label={"Configuración"} to={"/cargas"} /> */}
        </ul>
      </div>
    </div>
  );
};

const SidebarActions = () => {
  const { setTokens } = useContext(AuthContext)!;

  return (
    <div>
      <div className="border-t border-t-gray-200 pb-4" />
      <Link
        to={logoutUrl().href}
        onClick={(e) => {
          setTokens(null, null, null);
        }}
        className="flex flex-row items-center gap-2"
      >
        <ArrowLeftOnRectangleIcon className="h-6" />
        <span>Cerrar sesión</span>
      </Link>
    </div>
  );
};

const Sidebar = () => {
  return (
    <div className="flex-shrink-0 h-screen sticky top-0 left-0 bottom-0 w-72 px-4 py-2">
      <div className="flex flex-col w-full h-full rounded-2xl shadow-md px-4 py-6 overflow-y-auto">
        <SidebarHeader />
        <SidebarNav />
        <SidebarActions />
      </div>
    </div>
  );
};

export default Sidebar;
